import { createSlice } from "@reduxjs/toolkit";
export const loadingControlSlice = createSlice({
  name: "loadingState",
  //this is the initial state
  initialState: {
    value: false,
  },
  //reducers: these are the functions for updating the state of loadingState
  reducers: {
    updateLoadingState: (state, action) => {
      const { payload } = action
      state.value = payload
    },
  },
});

export const { updateLoadingState } = loadingControlSlice.actions;
export default loadingControlSlice.reducer;
