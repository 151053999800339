import { createSlice } from "@reduxjs/toolkit";

export const policyNumberSlice = createSlice({
  name: "policyNumber",
  //this is the initial state
  initialState: {
    value: "initial policy state",
    isVL: false
  },
  //reducers: these are the functions for updating the state of dark mode / light mode
  reducers: {
    updatePolicy: (state, action) => {
      const { payload } = action
      state.value = payload
      state.isVL = payload[0] === "V" || payload[0] === "v" ? true : false
    },
    clearPolicy: (state) => {
      state.value = null;
    },
  },

});


export const { updatePolicy, clearPolicy } = policyNumberSlice.actions;

export default policyNumberSlice.reducer;
