import { createSlice } from "@reduxjs/toolkit";
export const registrationSlice = createSlice({
  name: "registration",
  //this is the initial state
  initialState: {
    policyNumber: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    birthday: "",
    oldUserID: ""
  },
  //reducers: these are the functions for updating the state of dark mode / light mode
  reducers: {
    trackOldUserID: (state, action) => {
      console.log("action.payload?.oldUserID", action.payload?.oldUserID)
      console.log("action.payload", action.payload)

      state.oldUserID = action.payload

    },
    page1: (state, action) => {
      // console.log({ payload: action.payload })

      state.policyNumber = action.payload?.policyNumber,
        state.firstName = action.payload?.firstName,
        state.middleName = action.payload?.middleName,
        state.lastName = action.payload?.lastName,
        state.suffix = action.payload?.suffix,
        state.birthday = action.payload?.birthday
    },
    page2: (state, action) => {
      // console.log({ payload: action.payload })

      state.emailAddress = action.payload?.emailAddress,
        state.mobileNumber = action.payload?.mobileNumber,
        state.username = action.payload?.username,
        state.password = action.payload?.password
    },
  },
});

export const { trackOldUserID, page1, page2 } = registrationSlice.actions;
export default registrationSlice.reducer;
