import { createSlice } from "@reduxjs/toolkit";
export const gloesAccountSlice = createSlice({
  name: "gloesAccount",
  //this is the initial state
  initialState: {
    multiLoginRecord: '',
    initialLogin: false,
    token: '',
    userRole: [],
    userData: {}
  },
  //reducers: these are the functions for updating the state of dark mode / light mode
  reducers: {

    setToken: (state, action) => {
      state.token = action.payload
    },

    setUserRole: (state, action) => {
      state.userRole = action.payload
    },

    setUserData: (state, action) => {
      state.userData = action.payload
    },

    setInitialLogin: (state, action) => {
      state.initialLogin = action.payload
    },

    setMultiLoginRecord: (state, action) => {
      state.multiLoginRecord = action.payload
    }

  },
});

export const { setToken, setUserRole, setUserData, setInitialLogin, setMultiLoginRecord } = gloesAccountSlice.actions;
export default gloesAccountSlice.reducer;
