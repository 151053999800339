import React,
{
    createContext,
    useContext,
    useState,
    useEffect
} from "react"
import { auth } from "./Firebase/firebase"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'

const AuthContext = createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentFirebaseUser, setCurrentFirebaseUser] = useState()
    const location = useLocation();

    useEffect(() => {
        console.log("AuthProvider useEffect")

        // firebaseLogout()
        // .then(async (firebaseResponse) => {
        //   console.log("successful logout from firebase")

        // }).catch((error) => {
        //   console.error("unsuccessful logout from firebase:", error);
        // })

        const unsubscribe = auth.onAuthStateChanged(user => {
            // const pathname = window.location.pathname
            // console.log("pathname", pathname);
            // console.log("location", location);

            // console.log("Firebase auth", user)
            // if (user != null) {
            //     console.log("Firebase onAuthStateChanged", user)
            //     setCurrentFirebaseUser(user)
            // } else {
            //     console.log("Navigating to auth since firebaseuser is null")
            //     // navigate("/");
            // }
            setCurrentFirebaseUser(user)
        })
        return unsubscribe
    }, [])

    function firebaseSignup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function firebaseLogin(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function firebaseLogout() {
        return auth.signOut()
    }

    function getCurrentFirebaseUser() {
        let user = auth.currentUser
        return user
    }

    const value = {
        currentFirebaseUser,
        getCurrentFirebaseUser,
        firebaseSignup,
        firebaseLogin,
        firebaseLogout
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}