import { createSlice } from "@reduxjs/toolkit";
export const authSlice = createSlice({
  name: "auth",
  //this is the initial state
  initialState: {
    logoutDesc: '',
  },
  //reducers: these are the functions for updating the state of dark mode / light mode
  reducers: {

    setLogoutDesc: (state, action) => {
      state.logoutDesc = action.payload
    },

  },
});

export const { setLogoutDesc } = authSlice.actions;
export default authSlice.reducer;
