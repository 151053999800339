import { createSlice } from "@reduxjs/toolkit";

const loginEndpoints = {
  externalLogin: 'externalLogin',
  adminLogin: 'adminLogin'
}

const loginEndpointSetter = gloesAccessMode => {

  console.log("gloesAccessMode: ", gloesAccessMode);

  const defaultEndpoint = loginEndpoints.externalLogin

  if (gloesAccessMode === 'DEBUG') {
    console.log("loginEndpointSetter: ", defaultEndpoint);
    return defaultEndpoint
  }

  if (gloesAccessMode === 'EXTERNAL') {
    console.log("loginEndpointSetter: ", loginEndpoints.externalLogin);
    return loginEndpoints.externalLogin
  }

  if (gloesAccessMode === 'INTERNAL') {
    console.log("loginEndpointSetter: ", loginEndpoints.adminLogin);
    return loginEndpoints.adminLogin
  }

  return defaultEndpoint
}

export const gloesAccessMode = createSlice({
  name: "gloesAccessMode",
  //this is the initial state
  initialState: {
    gloesAccessMode: process.env.REACT_APP_GLOES_ACCESS_TYPE || 'EXTERNAL',
    loginEndpoint: loginEndpointSetter(process.env.REACT_APP_GLOES_ACCESS_TYPE)
  },
  //reducers: these are the functions for updating the state of dark mode / light mode
  reducers: {

    setGloesAccessMode: (state, action) => {
      state.gloesAccessMode = action.payload
      state.loginEndpoint = loginEndpointSetter(action.payload)
    }

  },
});

export const { setGloesAccessMode } = gloesAccessMode.actions;
export default gloesAccessMode.reducer;
