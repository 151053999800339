import { createSlice } from "@reduxjs/toolkit";
export const websiteMode = createSlice({
  name: "websiteMode",
  //this is the initial state
  initialState: {
    mode: 'gloes',
  },
  //reducers: these are the functions for updating the state of dark mode / light mode
  reducers: {

    setWebsiteMode: (state, action) => {
      state.mode = action.payload
    },

  },
});

export const { setWebsiteMode } = websiteMode.actions;
export default websiteMode.reducer;
